<template>
  <div class="overflow-hidden" style="background-color: #F8F8FC">
    <b-table class="table-support-sizes"
             :thead-tr-class="'invisible'"
             :items="itemsSizes"
             :fields="tableFields"
    >
      <template #head(newServices)="data">
        <div style="min-width: 200px"></div>
      </template>
      <template #cell(name)="data">
        <div style="width: 200px; height: 30px"></div>
      </template>
      <template #cell(costOWork)="data">
        <div style="width: 200px">

        </div>
      </template>
      <template #cell(number)="data">
        <div style="width: 200px;" class="position-relative"
             v-for="(size, i) in currentProductOnSupply.amountBySize"
             :key="i">
          <f-input-def
              v-bind:modelValue="size.amount"
              @input="function(val) {totalAmountUpdate({id: productId, size: size.size, amount: +val})}"
              class="d-block mb-1"
              placeholder="0"
          />
          <div class="position-absolute label-size">
            {{ size.size }}:
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BFormSelect,
  BLink,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import FInput from "@/components/UI/FInput";
import FInputDef from "@/components/UI/FInputDef";

export default {
  components: {
    FInputDef,
    FInput,
    BCol,
    BRow,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BLink,
    BFormInput,
  },
  name: "AmountBySizes",
  data() {
    return {
      itemsSizes: [
        {
          name: '',
          number: '',
          num_ff: '',
          num_mp: ''
        }
      ]
    }
  },
  methods: {
    ...mapActions('currentSupply', ['totalAmountUpdate']),

  },
  props: {
    productId: Number
  },
  computed: {
    ...mapState({
      productsOnSupply: state => state.currentSupply.products,
      tableFields: state => state.services.tableFields
    }),
    currentProductOnSupply() {
      return this.productsOnSupply.find(ps => ps.id === this.productId)
    }
  },
}
</script>

<style scoped>
.table-detail-sizes td {
  padding: 0;
  margin: 0;
}

.hidden-header {
  opacity: 0;
}

.label-size {
  right: 110%;
  top: 7px;
  font-style: italic;
}

.table-support-sizes {
  position: relative;
  top: -45px;
}


</style>