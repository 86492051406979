<template>
  <b-card>
    <div class="header-supply-blank d-flex justify-content-between align-items-start">
      <h3>
        Поставка
        <span v-if="supplyNumbID">{{ `#${supplyNumbID}` }}</span>
        {{ `от ${supplyDate}` }}
      </h3>
      <div class="mp-warehouse-container"
           v-if="selectedMpID"
      >
        <div class="mp" :style="`color: ${getCurrentMp.color}`">{{ getCurrentMp.name }}</div>
        <div class="warehouse">{{ getWarehouseName }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-start flex-wrap">
      <div class="mt-1">
        <div class="d-flex justify-content-between align-items-baseline">
          <div class="mr-2 mb-1">Стоимость работ:</div>
          <div>
            <span style="font-weight: bold; color: #8A859B; font-size: 20px" v-if="getFullPrice"> ₽ {{
                getFullPrice
              }}</span>
            <span v-else> --- </span>
          </div>
        </div>
<!--        <div class="d-flex justify-content-between">
          <div class="mr-2 mb-1">Объем товара:</div>
          <div>
            <span v-if="volumeOfGoods"> {{ volumeOfGoods }}</span>
            <span v-else> -&#45;&#45; </span>
          </div>
        </div>-->
        <div class="d-flex justify-content-between">
          <div class="mr-2 mb-1">Плановая дата поставки:</div>
          <div>
            <span v-if="plannedDeliveryDate"> {{ plannedDeliveryDate }}</span>
            <span v-else> {{ getPlannedDeliveryDate }} </span>
          </div>
        </div>
      </div>
      <div class="mt-1">
        <div class="d-flex justify-content-between">
          <div class="mr-2 mb-1">Товарных позиций:</div>
          <div>
            <span style="font-weight: bold" v-if="selectedProductsId.length"> {{ selectedProductsId.length }}</span>
            <span v-else> --- </span>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="mr-2 mb-1">Количество товаров:</div>
          <div>
            <span style="font-weight: bold" v-if="getNumOfGoods"> {{ getNumOfGoods }}</span>
            <span v-else> --- </span>
          </div>
        </div>
        <div class="d-flex">
          <svg class="mr-1" v-if="noTransit" width="20" height="20" viewBox="0 0 20 20" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 9H12.82C10.5 9 11.3501 9 10 9C8.64987 9 10 9 7.18 9H4C3.67 9 2 8.9 2 7V6C2 4.17 3.54 4 4 4H14.18C14.6 5.16 15.7 6 17 6C17.7956 6 18.5587 5.68393 19.1213 5.12132C19.6839 4.55871 20 3.79565 20 3C20 2.20435 19.6839 1.44129 19.1213 0.878679C18.5587 0.31607 17.7956 0 17 0C15.7 0 14.6 0.84 14.18 2H4C2.39 2 0 3.06 0 6V7C0 9.94 2.39 11 4 11H7.18C10 11 8.7 11 10 11C11.3 11 11.5 11 12.82 11H16C16.33 11 18 11.1 18 13V14C18 15.83 16.46 16 16 16H5.82C5.4 14.84 4.3 14 3 14C2.20435 14 1.44129 14.3161 0.87868 14.8787C0.316071 15.4413 0 16.2044 0 17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20C4.3 20 5.4 19.16 5.82 18H16C17.61 18 20 16.93 20 14V13C20 10.07 17.61 9 16 9ZM17 2C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3C18 3.26522 17.8946 3.51957 17.7071 3.70711C17.5196 3.89464 17.2652 4 17 4C16.7348 4 16.4804 3.89464 16.2929 3.70711C16.1054 3.51957 16 3.26522 16 3C16 2.73478 16.1054 2.48043 16.2929 2.29289C16.4804 2.10536 16.7348 2 17 2ZM3 18C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17C2 16.7348 2.10536 16.4804 2.29289 16.2929C2.48043 16.1054 2.73478 16 3 16C3.26522 16 3.51957 16.1054 3.70711 16.2929C3.89464 16.4804 4 16.7348 4 17C4 17.2652 3.89464 17.5196 3.70711 17.7071C3.51957 17.8946 3.26522 18 3 18Z"
                fill="#7367F0"/>
          </svg>
          <svg class="mr-1" v-if="freeInspection" width="20" height="20" viewBox="0 0 20 20" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#DCF7E7"/>
            <path
                d="M10.3594 11.5229H4.81494V10.0654H10.3228C10.8159 10.0654 11.2139 9.98975 11.5166 9.83838C11.8193 9.68213 12.0415 9.46484 12.1831 9.18652C12.3247 8.9082 12.3955 8.5835 12.3955 8.2124C12.3955 7.88037 12.3247 7.57031 12.1831 7.28223C12.0415 6.99414 11.8218 6.75977 11.5239 6.5791C11.2261 6.39355 10.8379 6.30078 10.3594 6.30078H8.14746V15.5H6.30908V4.83594H10.3594C11.1797 4.83594 11.8779 4.97998 12.4541 5.26807C13.0303 5.55615 13.4722 5.9541 13.7798 6.46191C14.0874 6.96484 14.2412 7.54346 14.2412 8.19775C14.2412 8.896 14.085 9.49414 13.7725 9.99219C13.4648 10.4854 13.0205 10.8638 12.4395 11.1274C11.8633 11.3911 11.1699 11.5229 10.3594 11.5229ZM10.5352 12.3066V13.7642H4.81494V12.3066H10.5352Z"
                fill="#28C76F"/>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="step === 0" class="first-setting d-flex flex-wrap mt-1">
      <div class="mr-2">
        <label class="d-block font-weight-bold" for="input-live">Выберите маркетплейс:</label>
        <b-form-select
            class="filter-supply-fields m-0"
            v-model="selectedMpID"
            :options="listMP"
        ></b-form-select>
      </div>
      <div class="mr-2">
        <label class="d-block font-weight-bold" for="input-live">Выберите склады:</label>
        <b-form-select
            class="filter-supply-fields m-0"
            v-model="selectedWarehouseID"
            :options="getListWarehouses"
        ></b-form-select>
      </div>
      <div class="mr-2">
        <label class="d-block font-weight-bold" for="input-live">Выберите дату поставки:</label>
        <b-form-datepicker
            v-model="plannedDeliveryDate"
            :min="new Date()"
            locale="ru"
        >
        </b-form-datepicker>
      </div>
    </div>
    <div class="first-setting d-flex justify-content-between align-items-center flex-wrap mt-1">
      <div v-if="step === 0" class="d-flex flex-wrap">
        <b-form-checkbox class="mr-1" v-model="noTransit" name="check-button" switch>
          Без транзита
        </b-form-checkbox>
        <b-form-checkbox class="mr-1" v-model="freeInspection" name="check-button" switch>
          Только бесплатная приемка
        </b-form-checkbox>
      </div>
      <b-button
          v-if="step > 0"
          @click="$emit('prevTab', step)"
          variant="outline-primary"
      >Назад
      </b-button>
      <b-button
          v-if="step<2"
          :variant=" getNext ? 'outline-primary':''"
          @click="getNext ? $emit('nextTab', step) : null"
      >Далее
      </b-button>
      <b-button
          v-else-if="step===2"
          :variant=" getNext ? 'outline-primary':''"
          @click="sendDelivery"
      >Оформить поставку
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {getCurrentDate} from '@/libs/dates'
import {mapState} from "vuex"

import {
  BButton,
  BCard,
  BCardText,
  BFormCheckbox,
  BFormDatepicker,
  BFormInput,
  BFormSelect,
  BLink,
  BSpinner,
  BTable,
} from 'bootstrap-vue'

export default {
  name: "SupplyBlank",
  components: {
    BCard,
    BTable,
    BButton,
    BSpinner,
    BCardText,
    BLink,
    BFormInput,
    BFormSelect,
    BFormDatepicker,
    BFormCheckbox,
  },
  props: {
    supplyNumbID: {
      type: Number,
    },
    mp: {
      type: Array,
    },
    priceServices: {
      type: Number
    },
    volumeOfGoods: {
      type: Number
    },
    numPositions: {
      type: Number
    },
    numOfGoods: {
      type: Number
    },
    step: {
      type: Number,
    },
    next: Boolean
  },
  data() {
    return {
      supplyDate: getCurrentDate(),
      selectedMpID: 3,
      selectedWarehouseID: 1,
      noTransit: true,
      freeInspection: true,
      plannedDeliveryDate: null,
      listMP: null,
    }
  },
  methods: {
    sendDelivery() {
      this.$router.push('/delivery');
    }
  },
  computed: {
    getFullPrice() {
      if (this.triggerSelect && this.triggerSetTasks) {
        console.log()
        return this.selectedProductsId.reduce((acc, prod) => {
          return acc + prod.shipment.totalCost
        }, 0)
      }
    },
    getNumOfGoods() {
      if (this.triggerSelect && this.triggerSetTasks) {
        return this.selectedProductsId.reduce((acc, prod) => {
          return acc + (+prod.totalAmount || 0)
        }, 0)
      }
    },
    ...mapState('products', ["selectedProductsId", "triggerSelect", "triggerSetTasks"]),
    getNext() {
      return this.selectedMpID &&
          this.selectedWarehouseID &&
          this.next
    },
    getPlannedDeliveryDate() {
      return getCurrentDate()
    },
    getCurrentMp() {
      return this.mp.find(mp => mp.id === this.selectedMpID)
    },
    getWarehouseName() {
      if (this.selectedWarehouseID) {
        return this.getListWarehouses.find(w => w.value === this.selectedWarehouseID).text;
      }
    },
    getListWarehouses() {
      if (this.selectedMpID) {
        return this.getCurrentMp.warehouse.map(wh => ({value: wh.id, text: wh.name}));
      }
    }
  },
  mounted() {
    this.listMP = this.mp.map(mp => ({value: mp.id, text: mp.name}));
  }
}
</script>

<style scoped>
.mp {
  font-size: 20px;
}

.mp-warehouse-container {
  text-align: end;
}

.filter-supply-fields {
  width: 200px;
  margin: 0 10px;
}

.warehouse {
  font-size: 14px;
}
</style>