<template>
  <div :class="bold ? 'cell-total-amount-bold' : 'cell-total-amount'" style="width: 100px"
       @click="openDetail"
  >{{ getAmount }}</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CellTotalAmountShipments",
  props: {
    productID: Number,
    bold: Boolean,
  },

  data() {
    return {
      amount: 0
    }
  },
  methods: {
    openDetail() {
      this.$emit('openDetail')
    },
  },
  computed: {
    getAmount() {
      if (this.triggerSelect) return this.selectedProductsId.find(prod => prod.id === this.productID)?.totalAmount || 0;
    },
    ...mapState('products', ['selectedProductsId', 'triggerSelect']),
  },
  watch: {}
}
</script>

<style scoped>
.cell-total-amount {
  padding: 0.438rem 1rem;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
.cell-total-amount-bold {
  font-weight: 500;
}
</style>