<template>
  <b-card>
    <div class="total-price-checks py-1 font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
      <h4>Количество по каждой позиции</h4>
      <b-button
          variant="outline-primary"
          size="sm"
          class="d-flex align-items-center"
          @click="allFromFfToMp"
      >
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.41667 0C2.85833 0 0 2.85833 0 6.41667C0 9.975 2.85833 12.8333 6.41667 12.8333C9.975 12.8333 12.8333 9.975 12.8333 6.41667C12.8333 2.85833 9.975 0 6.41667 0ZM6.41667 11.6667C3.5 11.6667 1.16667 9.33333 1.16667 6.41667C1.16667 3.5 3.5 1.16667 6.41667 1.16667C9.33333 1.16667 11.6667 3.5 11.6667 6.41667C11.6667 9.33333 9.33333 11.6667 6.41667 11.6667ZM9.29673 6.25997C9.28501 6.23166 9.275 6.2075 9.275 6.18333C9.275 6.125 9.21667 6.06667 9.15833 6.00833L6.825 3.675C6.59167 3.44167 6.24167 3.44167 6.00833 3.675C5.775 3.90833 5.775 4.25833 6.00833 4.49167L7.35 5.83333H4.08333C3.73333 5.83333 3.5 6.06667 3.5 6.41667C3.5 6.76667 3.73333 7 4.08333 7H7.35L6.00833 8.34167C5.775 8.575 5.775 8.925 6.00833 9.15833C6.125 9.275 6.24167 9.33333 6.41667 9.33333C6.59167 9.33333 6.70833 9.275 6.825 9.15833L9.15833 6.825C9.21667 6.76667 9.275 6.70833 9.275 6.65C9.33333 6.59167 9.33333 6.475 9.33333 6.41667C9.33333 6.34833 9.31332 6.3 9.29673 6.25997Z" fill="#7367F0"/>
        </svg>
        <span class="ml-1" >все с ФФ на МП</span>
      </b-button>
    </div>
    <div>По каждой позиции укажите количество. Если товар имеет размеры, необходимо указать количество хотя бы для одного размера.</div>
    <div class="py-1 d-flex flex-wrap justify-content-between align-items-start">
      <div class="mt-1 mr-1 d-flex align-items-center">
        <label class="mr-1">Поиск:</label>
        <b-form-input
            placeholder="Поиск"
            type="text"
            class="d-inline-block"
            @input="findFilter"
        />
      </div>
      <div class="d-flex flex-wrap">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Бренд:</label>
          <b-form-select
              v-model="brandFilter"
              :options="listAllBrands"
          />
        </div>
        <div class="d-flex mt-1 align-items-center">
          <label class="mr-1">Категория:</label>
          <b-form-select
              v-model="objectFilter"
              :options="listAllCategories"
          />
        </div>
      </div>
    </div>
    <b-table
        id="table_products_to_shipment"
        class="m-0"
        responsive
        :thead-tr-class="'text-nowrap'"
        :fields="fields"
        :details-td-class="'p-0'"
        :items="selectedProductsId"
    >
      <template #head(name)="data">
        <span style="width: 200px">{{ data.label }}</span>
      </template>
      <template #head(amountOnShipments)="data">
        <div style="width: 180px">{{ data.label }}</div>
      </template>
      <template #head(stockff)="data">

      </template>
      <template #head(stockmp)="data">
        <span style="width: 50px; padding-right: 10px">{{ data.label }}</span>
      </template>
      <template #cell(name)="data">
        <product-cell
            @openDetail="data.toggleDetails()"
            :_showDetails="data.item._showDetails"
            :detailsShowing="Array.isArray(data.item.stockff) || Array.isArray(data.item.stockmp)"
            :imgData="{
            id: data.item.id,
            img: data.item.img[0],
            name: data.item.name,
            article: data.item.article,
            brand: data.item.brand,
          }"
        />
      </template>
      <template #cell(amountOnShipments)="data">
        <div style="width: 180px"
             v-if="data.item.sizes[0].value === false && triggerSelect">
          <b-form-input
              style="width: 100px"
              :value="data.item.totalAmount"
              placeholder="0"
              @input="function(val) {setTotalAmount({val, productId: data.item.id})}"
          />
        </div>
        <div v-else class="d-flex align-items-center">
          <cell-total-amount-shipments
              @openDetail="data.toggleDetails()"
              :productID="data.item.id"
          />
          <b-button class="ml-1" variant="outline-primary" size="sm"
                    @click="allFromFfToMpItem(data.item)"
          >Все
          </b-button>
        </div>

      </template>
      <template #cell(stockff)="data">
        <div style="width: 50px">{{
            data.item.sizes[0] && data.item.sizes[0].value === false ? data.item.sizes[0].ff : data.item.sizes.reduce((a, size) => {
              if (size.value) return a + size.ff
            }, 0)
          }}
        </div>
      </template>
      <template #cell(stockmp)="data">
        <div style="width: 50px">{{
            data.item.sizes[0] && data.item.sizes[0].value === false ? data.item.sizes[0].mp : data.item.sizes.reduce((a, size) => {
              if (size.value) return a + size.mp
            }, 0)
          }}
        </div>
      </template>
      <template #row-details="data">
        <table-amount-products-size
            :productId="data.item.id"
            @calcTotalAmount="setTotalAmount"
            :delivery="true"
        />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import FInputDef from "@/components/UI/FInputDef";
import FInput from "@/components/UI/FInput";
import TableAmountProductsSize from '@/components/shipments/TableAmountProductsSize'
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BLink,
  BRow,
  BSpinner,
  BTable
} from "bootstrap-vue";

import ProductCell from "@/components/ProductCell";
import CellTotalAmountShipments from "@/components/CellTotalAmountShipments";

export default {
  components: {
    ProductCell,
    FInputDef,
    FInput,
    BFormSelect,
    BCol,
    BRow,
    BFormCheckbox,
    TableAmountProductsSize,
    CellTotalAmountShipments,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BLink,
    BFormInput,
  },
  name: "TableSetNumb",
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Товары',
        },
        {
          key: 'amountOnShipments',
          label: 'количество',
        },
        {
          key: 'stockff',
          label: 'На ФФ ',
          sortable: true,
        },
        {
          key: 'stockmp',
          label: 'на МП',
          sortable: true,
        },
      ],
      findFilter: '',
      brandFilter: '',
      objectFilter: '',
    }
  },
  methods: {
    ...mapActions('products', ["setTotalAmount", "allFromFfToMpItem", "allFromFfToMp"]),
  },
  props: {
    productId: Number,
  },
  computed: {
    getAllTotalAmount() {
      if (this.triggerSelect > 0) {
        let res = this.selectedProductsId.every(prod => {
          return +prod.totalAmount > 0
        });
        return res;
      }
    },
    ...mapState('products', ["productsWidthWB", "selectedProductsId", 'listAllCategories', "listAllBrands", "triggerSelect"]),
  },
  watch: {
    getAllTotalAmount(val) {
      this.$emit('validData', val)
    }
  }
}
</script>

<style scoped>

</style>