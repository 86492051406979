<template>
  <b-card>
    <div class="header-supply flex-wrap d-flex justify-content-between">
      <h4 class="w-100">
        Выберите услуги
      </h4>
      <div style="min-width: 320px">
        Сперва выберите нужные услуги, затем выберите товары отметив нужную услугу на против товара.
      </div>
    </div>
    <div class="panel-find d-flex justify-content-between flex-wrap">
      <div class="my-1 filter-supply d-flex align-items-center">
        <label for="input-default">Поиск:</label>
        <b-form-input class="filter-supply-fields" placeholder="Имя, бренд, артикул"></b-form-input>
      </div>
      <div class="d-flex justify-content-between flex-wrap" style="max-width: 520px">
        <div class="my-1 filter-supply d-flex align-items-center">
          <label for="input-default">Бренд:</label>
          <b-form-select class="filter-supply-fields" v-model="selectedBrand"
                         :options="optionsBrand"></b-form-select>
        </div>
        <div class="my-1 filter-supply d-flex align-items-center">
          <label for="input-default">Категория:</label>
          <b-form-select style="margin-right: 0" class="filter-supply-fields" v-model="selectedCategory"
                         :options="optionsCategory"></b-form-select>
        </div>
      </div>
    </div>
    <b-table
        style="min-height: 600px"
        class="table-supply"
        id="table_supply"
        :details-td-class="'p-0 test-class'"
        :tbody-class="'table-supply-tbody'"
        :fields="tableFields"
        :filter="filter"
        :items="selectedProductsId"
        responsive
    >
      <template #head(newServices)="data">
        <div class="position-relative new-serv-container"
             @click="toggleListServ">
          <span class="cursor-pointer position-relative">
            {{ data.label }}
            <svg
                class="position-absolute butt-add-serv cursor-pointer"
                width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#7367F0"/>
            <path
                d="M15 10C15 10.375 14.75 10.625 14.375 10.625H10.625V14.375C10.625 14.75 10.375 15 10 15C9.625 15 9.375 14.75 9.375 14.375V10.625H5.625C5.25 10.625 5 10.375 5 10C5 9.625 5.25 9.375 5.625 9.375H9.375V5.625C9.375 5.25 9.625 5 10 5C10.375 5 10.625 5.25 10.625 5.625V9.375H14.375C14.75 9.375 15 9.625 15 10Z"
                fill="white"/>
          </svg>
          </span>
          <div class="position-absolute container-list-services"
               v-if="showListService">
            <menu>
              <li
                  @click.stop
                  v-if="serv.key !== 'second_pack' || (serv.key === 'second_pack' && selectedServ['first_pack'])"
                  class="d-flex serv-item align-items-center"
                  v-for="(serv, i) in listServices"
                  :key="i"
              >
                <b-form-checkbox
                    :id="`item_serv_${i}`"
                    v-model="selectedServ[serv.name]"
                    class="check-serv"
                    @change="function(val) {toggleSetServ(val, serv)}"
                />
                <label class="cursor-pointer"
                       :for="`item_serv_${i}`">{{ serv.label }}</label>
              </li>
            </menu>
          </div>
        </div>
      </template>

      <template #head(firstPack)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <select-pack
            v-if="triggerSetTasks"
            @input="function(pack) {selectPackForAllProducts(pack)}"
            :localState="true"
        />
      </template>
      <template #head(secondPack)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <select-pack-second
            v-if="triggerSetTasks"
            @input="function(pack) {selectPackForAllProducts(pack)}"
            :localState="true"
        />
      </template>
      <template #head(checkDefect)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              @change="function(val) {selectTaskForAllProducts(val, 'checkDefect')}"
          />
        </div>
      </template>
      <template #head(equipment)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">

          <b-form-checkbox
              @change="function(val) {selectTaskForAllProducts(val, 'equipment')}"
          />
        </div>
      </template>
      <template #head(sorting)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              @change="function(val) {selectTaskForAllProducts(val, 'sorting', data.item)}"
          />
        </div>
      </template>
      <template #head(markProd)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              @change="function(val) {selectTaskForAllProducts(val, 'markProd')}"
          />
        </div>
      </template>
      <template #head(markPackage)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              @change="function(val) {selectTaskForAllProducts(val, 'markPackage')}"
          />
        </div>
      </template>
      <template #head(removeTag)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              @change="function(val) {selectTaskForAllProducts(val, 'removeTag')}"
          />
        </div>
      </template>
      <template #head(addTag)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              @change="function(val) {selectTaskForAllProducts(val, 'addTag')}"
          />
        </div>
      </template>
      <template #head(linerAttachment)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              @change="function(value) {selectTaskForAllProducts(value, 'linerAttachment')}"
          />
        </div>
      </template>
      <template #head(steaming)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              @change="function(val) {selectTaskForAllProducts(val, 'steaming')}"
          />
        </div>
      </template>
      <template #head(sealTheBox)="data">
        <div class="d-flex justify-content-center align-items-center mb-1"> {{ data.label.toUpperCase() }}</div>
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              @change="function(val) {selectTaskForAllProducts(val, 'sealTheBox')}"
          />
        </div>
      </template>

      <template #cell(name)="data">
        <product-cell
            :modelValue="listProductsForTasks.some(prod=>prod.id === data.item.id)"
            :imgData="{
                id: data.item.id,
                img: data.item.img[0],
                name: data.item.name,
                article: data.item.article,
                brand: data.item.brand,}"
            @selectItem="function(val) {addProductToTasks(data.item, val)}"
        />
      </template>
      <template #cell(number)="data">
        <cell-total-amount-shipments
            :productID="data.item.id"
            :bold="true"
        />
      </template>
      <template #cell(firstPack)="data">
        <select-pack
            v-if="triggerSetTasks"
            :currentPack="data.item.shipment.taskList.find(task=>task.parentName === 'firstPack')"
            @input="function(pack) {setPacking({pack, productId: data.item.id})}"
        />
      </template>
      <template #cell(secondPack)="data">
        <select-pack-second
            v-if="triggerSetTasks"
            :currentPack="data.item.shipment.taskList.find(task=>task.parentName === 'secondPack')"
            @input="function(pack) {setPacking({pack, productId: data.item.id})}"
        />
      </template>
      <template #cell(checkDefect)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'checkDefect')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'checkDefect',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(equipment)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'equipment')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'equipment',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(sorting)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'sorting')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'sorting',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(markProd)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'markProd')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'markProd',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(markPackage)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'markPackage')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'markPackage',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(removeTag)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'removeTag')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'removeTag',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(addTag)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'addTag')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'addTag',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(linerAttachment)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'linerAttachment')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'linerAttachment',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(steaming)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'steaming')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'steaming',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(sealTheBox)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox
              :checked="data.item.shipment.taskList.some(task=>task.name === 'sealTheBox')"
              @change="function(value) {addTaskToDelivery({value, taskName: 'sealTheBox',product: data.item})}"
          />
        </div>
      </template>
      <template #cell(costOWork)="data">
        <div v-if="triggerSetTasks && localTrigger" style="width: 200px; font-weight: bold">
          {{
            data.item.shipment.totalCost
          }}
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BFormSelect,
  BLink,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'

import {mapState, mapActions, mapGetters} from 'vuex'
import ProductCell from "@/components/ProductCell";
import InputNumb from "@/components/InputNumb";
import AmountBySizes from "@/components/delivery/AmountBySizes";
import FInput from "@/components/UI/FInput";
import SelectPack from "@/components/UI/SelectPack";
import SelectPackSecond from "@/components/UI/SelectPackSecond";
import CellTotalAmountShipments from "@/components/CellTotalAmountShipments";

export default {
  components: {
    CellTotalAmountShipments,
    BDropdown,
    BDropdownItem,
    SelectPack,
    FInput,
    BCol,
    SelectPackSecond,
    BRow,
    BTable,
    BButton,
    BSpinner,
    BCard,
    AmountBySizes,
    BCardText,
    BLink,
    BFormInput,
    BFormSelect,
    ProductCell,
    InputNumb,
    BFormCheckbox
  },
  name: "TableSelectTechTask",
  data() {
    return {
      test: 0,
      filter: '',
      localTrigger: 1,
      listProductsForTasks: [],
      showListService: false,
      selectedBrand: null,
      optionsBrand: [
        {value: null, text: 'Все бранды'},
      ],
      selectedServ: {},
      selectedCategory: null,
      optionsCategory: [
        {value: null, text: 'Все категории'},
      ],
      numbToSupply: {},
    }
  },
  computed: {
    ...mapState('products', [
      "triggerSetTasks",
      "triggerSelect",
      "selectedProductsId"
    ]),
    ...mapState('services', [
      "listServices",
      "tableFields",
      "services"
    ]),
  },
  methods: {
    totalAmountByProduct(product) {
      let price = product.shipment.taskList.reduce((acc, task) => {
        if (typeof task.price === "number") return task.price + acc;
        else return Object.values(task.price)[2] + acc
      }, 0)
      return price * product.totalAmount
    },
    ...mapActions('currentSupply', [
      'addProductsToSupply',
      'addProductToSupply',
      "addServiceToProductSupply",
      "delServiceToProductSupply",
      "delServiceToAllProductSupply",
    ]),
    ...mapActions('services', ['addService', 'delService']),
    ...mapActions('products', ['addTestProduct', 'delTestProduct', 'setTotalAmount', "delTaskFromAllProducts", "addTaskToAllProducts",
      "setPacking",
      "addTaskToDelivery",]),
    toggleListServ() {
      this.showListService = !this.showListService
    },
    toggleSetServ(val, serv) {
      const {id, name} = serv;
      if (val) {
        this.addService(id);
        this.selectedServ[name] = true;
      } else {
        this.delService(id);
        this.selectedServ[name] = false;
        this.delTaskFromAllProducts(id);

        let table_supply = document.querySelector('.new-serv-container');
        setTimeout(() => {
          table_supply.scrollIntoView({
            behavior: "smooth", inline:
                "center"
          });
        }, 0)
      }
      let table_supply = document.querySelector('.table-supply');
      setTimeout(() => {
        //table_supply.scrollLeft = table_supply.scrollWidth;
        table_supply.scroll({left: table_supply.scrollWidth, behavior: "smooth",});
      }, 200)
    },
    addProductToTasks(product, val) {
      if (val) this.listProductsForTasks.push(product);
      else this.listProductsForTasks.splice(this.listProductsForTasks.findIndex(prod => prod.id === product.id), 1);
    },
    selectPackForAllProducts(pack) {
      this.selectedProductsId.forEach(product => {
        this.setPacking({pack, productId: product.id})
      })
    },
    selectTaskForAllProducts(val, taskName) {
      let task = this.listServices.find(task => task.name === taskName)
      if (val) {
        if (task) this.addTaskToAllProducts(task);
      } else this.delTaskFromAllProducts(task.id)
    },
  },
  watch: {
    triggerSelect() {
      this.localTrigger++
    },
  },
}
</script>

<style scoped>
.filter-supply {
  min-width: 200px;
}

.butt-add-serv {
  left: -25px;
  top: -2px
}

.filter-supply-fields {
  width: 180px;
  margin: 0 10px;
}

.container-list-services {
  top: 28px;
  left: -34px;
  min-width: 200px;
  z-index: 10;
}

.container-list-services menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.serv-item {
  height: 40px;
  border-top: 1px solid #ebe9f1;
  background-color: #F3F2F7;
  margin-right: 10px;
}

.check-serv {
  margin: 0 10px;
}

.add-product {
  background-color: white;
}

.new-serv-container {
  min-width: 200px;
}

.table-supply {
  transition-duration: 1500ms;
}

.header-supply {
  margin-bottom: 20px;
}

</style>