<template>
  <div class="product-cell d-flex position-relative align-items-center">
    <div v-if="productData.sizes[0].techSize && Number(productData.sizes[0].techSize) !== 0"
         class="btn-detail-cont cursor-pointer"
         @click="openDetail()"
    >
      <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg"
           v-if="productData._showDetails"
      >
        <path d="M15.8213 1.07129L8.92044 7.87056C8.88191 7.90853 8.82017 7.90897 8.7811 7.87156L1.67915 1.07129"
              stroke="#979797" stroke-width="1.4" stroke-linecap="round"/>
      </svg>
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"
           v-else
      >
        <path d="M0.75 1L7.54927 7.90085C7.58724 7.93938 7.58768 8.00112 7.55027 8.04019L0.75 15.1421"
              stroke="#979797" stroke-width="1.4" stroke-linecap="round"/>
      </svg>
    </div>
    <div v-else class="btn-detail-cont cursor-pointer"></div>
    <div class="product-img mr-1">
      <img style="width: 60px" :src="productData.imgMain" :alt="productData.subjectName">
    </div>
    <div>
      <div style="font-size: 13px" class="font-weight-bold">{{ productData.subjectName }}</div>
      <div style="font-size: 12px">{{ productData.vendorCode }}</div>
      <div style="font-size: 12px">{{ productData.brand }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
import {BFormCheckbox} from 'bootstrap-vue'

export default {
  name: "ProductCell",
  props: {
    selectable: Boolean,
    productData: {
      type: Object,
      default() {
        return {
          "skus": [],
          "price": 0,
          "wayMp": 0,
          "chrtId": 0,
          "wbSize": "",
          "stockmp": 0,
          "storage": 0,
          "currency": "RUB",
          "discount": 0,
          "techSize": 0,
          "promocode": null,
          "preparation": 0,
          "averagePrice": null,
          "discountedPrice": 0
        }
      }
    },
    modelValue: Boolean,
  },
  components: {
    BFormCheckbox
  },
  data() {
    return {}
  },
  methods: {
    openDetail() {
      this.$emit('openDetail');
    },
    selectItem(val) {
      this.$emit('selectItem', val);
    },
    ...mapActions('currentSupply', ['delProductFromSupply'])
  }
}

</script>

<style scoped>
.product-cell {
  width: 200px;
  overflow: hidden;
}

.product-img {
  max-height: 80px;
}

.btn-detail-cont {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
</style>