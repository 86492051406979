<template>
  <div>
    <SupplyBlank
        :mp="marketplaces"
        :supplyNumbID="7845"
        :step="step"
        :next="valid"
        @nextTab="nextTab"
        @prevTab="prevTab"
    />
    <keep-alive>
    <component
        v-if="step>0"
        :is="currentTabComponent[step]"></component>
    </keep-alive>
  </div>
</template>

<script>

import {
  BCol,
  BRow,
  BCard,
  BCardText,
  BLink,
  BTable,
  BButton,
  BSpinner,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import SupplyBlank from "@/components/delivery/SupplyBlank";
import TableSelectTechTask from "@/components/delivery/TableSelectTechTask";
import TableSetNumb from "@/components/delivery/TableSetNumb";

export default {
  components: {
    BCol,
    BRow,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormSelect,
    SupplyBlank,
    TableSelectTechTask,
    TableSetNumb,
  },
  name: "CreatingDelivery",
  data() {
    return {
      marketplaces: [
        {
          id: 3,
          color: '#9747ff',
          name: 'Wildberries',
          warehouse: [{name: 'Краснодар', id: 1}, {name: 'Москва', id: 2}, {name: 'Новосибирск', id: 3}]
        },
        {
          id: 4,
          color: '#332ff1',
          name: 'Ozon',
          warehouse: [{name: 'Пушкино-1', id: 1}, {name: 'Воронеж', id: 2}, {name: 'СПБ Бугры', id: 3}]
        },
      ],
      currentTabComponent: [
        'none',
        'TableSetNumb',
        'TableSelectTechTask'
      ],
      step: 0,
      valid: true,
    }
  },
  computed: {},
  methods: {
    nextTab() {
      if (this.step <= this.currentTabComponent.length - 1) this.step++
    },
    prevTab() {
      if (this.step > 0) this.step--
    },
  }
}
</script>

<style>

.header-supply h4 {
  font-size: 21px;
  font-weight: 500;
}

</style>
