<template>
  <div>
    <div v-if="currentProductOnSupply && !currentProductOnSupply.amountBySize">
      <f-input-def
          placeholder="0"
          v-bind:modelValue="currentProductOnSupply.totalAmount"
          @input="function(amount) {setAmount({id: productId, amount})}"
          type="number"
      />
    </div>
    <div v-else
         @click="openDetail()">
      <f-input
          v-model="currentProductOnSupply.totalAmount"
          type="number"
          @click="openDetail()"
          readonly
      />
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BFormSelect,
  BLink,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'

import {mapActions, mapState} from "vuex";
import FFInput from "@/components/UI/FInput";
import FInput from "@/components/UI/FInput";
import FInputDef from "@/components/UI/FInputDef";

export default {
  name: "InputNumb",
  components: {
    FInputDef,
    FInput,
    FFInput,
    BCol,
    BRow,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      localValue: null
    }
  },
  methods: {
    openDetail() {
      this.$emit('openDetail');
    },
    ...mapActions('currentSupply', ["setAmount"]),
    setValue(value) {
      this.currentProductOnSupply.totalAmount = this.localValue = value;
    },
  },
  computed: {
    ...mapState({productsOnSupply: state => state.currentSupply.products,}),
    currentProductOnSupply() {
      return this.productsOnSupply.find(ps => ps.id === this.productId);
    }
  },
  props: {
    productId: Number
  }
}
</script>

<style scoped>

</style>