<template>
  <div class="overflow-hidden" style="background-color: #F8F8FC">
    <b-table
        id="table_products_to_shipment_detail"
        class="detail-table"
        :thead-tr-class="'text-nowrap'"
        :fields="fields"
        :items="getItemsAmountBySize"
    >
      <template #head(name)="data">
        <div style="width: 200px">{{ data.label }}</div>
      </template>
      <template #head(amountOnShipments)="data">
        <div :style=" delivery ? 'width: 180px;' : 'width: 100px;'">{{ data.label }}</div>
      </template>
      <template #head(ff)="data">
        <div style="width: 50px">{{ data.label }}</div>
      </template>
      <template #head(mp)="data">
        <div style="width: 50px">{{ data.label }}</div>
      </template>
      <template #cell(name)="data">
        <div style="width: 200px" class="d-flex align-items-center">
          <div style="font-size: 14px" class="font-italic mr-1"> {{ data.item.name }}:</div>
          <b-form-input
              type="number"
              :autofocus="data.index === 0"
              style="max-width: 70px"
              v-model="data.item.shipment.amount[data.item.name]"
              placeholder="0"
              @input="function(val) {calcTotalAmount(false, data.item.name)}"
          />

        </div>
      </template>
      <template #cell(amountOnShipments)="data">
        <div :style=" delivery ? 'width: 180px;' : 'width: 100px;'" style="padding-left: 1rem">
          {{ data.item.shipment.amount[data.item.name] || 0 }}
        </div>
      </template>
      <template #cell(ff)="data">
        <div style="width: 50px">{{ data.item.ff || 0 }}</div>
      </template>
      <template #cell(mp)="data">
        <div style="width: 50px">{{ data.item.mp || 0 }}</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import ProductCell from "@/components/ProductCell";
import FInputDef from "@/components/UI/FInputDef";
import FInput from "@/components/UI/FInput";
import {BButton, BCard, BCardText, BCol, BFormCheckbox, BFormInput, BLink, BRow, BSpinner, BTable} from "bootstrap-vue";
import {mapState} from 'vuex'

export default {
  name: "TableAmountProductsSize",
  components: {
    ProductCell,
    FInputDef,
    FInput,
    BCol,
    BRow,
    BFormCheckbox,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BLink,
    BFormInput,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'размеры',
        },
        {
          key: 'amountOnShipments',
          label: 'На отгрузку',
        },
        {
          key: 'ff',
          label: 'На ФФ ',
          sortable: true,
        },
        {
          key: 'mp',
          label: 'на МП',
          sortable: true,
        },
      ],
      totalAmount: 0,
    }
  },
  props: {
    productId: Number,
    delivery: Boolean,
  },
  computed: {
    ...mapState('products', ['productsWidthWB', "selectedProductsId"]),
    getItemsAmountBySize() {
      let prod = this.selectedProductsId.find(prod => prod.id === this.productId);
      let sizes = {};
      for (const size of prod.sizes) {
        sizes[size.techSize || size.wbSize] = {
          name: size.techSize || size.wbSize,
          shipment: prod.shipment,
          ff: size.ff,
          mp: size.mp,
        }
      }
      return Object.values(sizes);
    },
  },
  methods: {
    calcTotalAmount(val, size) {
      this.$emit('calcTotalAmount', {val, productId: this.productId, size})
    },

  },
}
</script>

<style scoped>

</style>